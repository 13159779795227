<template>
  <section>
    <div class="container">
      <div class="section-title text-center">
        <h2 class="mb-0">
          <span>Our Partners</span>
        </h2>
      </div>
      <InfiniteScroll @isvisible="getData" :once="true">
        <div class="grid">
          <div class="item" v-for="item in data" :key="item.name">
            <img v-lazy="item.image" :alt="item.name" />
          </div>
        </div>
      </InfiniteScroll>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getData() {
      axios.get(this.$route("api.partners")).then((response) => {
        this.data = response.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 1rem;
  @media (max-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }
  .item {
    box-shadow: 0 0.275rem 0.75rem -0.0625rem rgb(19 16 34 / 6%),
      0 0.125rem 0.4rem -0.0625rem rgb(19 16 34 / 3%);
    border-radius: var(--border-radius);
    background: var(--white);
    overflow: hidden;
    img {
      width: 100%;
      height: 160px;
      object-fit: contain;
      padding: 0.4rem;
      @media (max-width: 576px) {
        height: 140px;
      }
    }
  }
}
</style>