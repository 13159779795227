// window.Vue = require('vue').default;

Vue.directive('active', {
    bind: function (el, binding, vnode) {
        if (binding.value) {
            if (route().current(binding.value)) {
                el.classList.add("active");
            } else {
                el.classList.remove("active");
            }
            return;
        }
        if (el.getAttribute("href")) {
            if (el.getAttribute("href") == window.location.href) {
                el.classList.add("active");
            } else {
                el.classList.remove("active");
            }
            return;
        }
    }
});