<template>
  <div>
    <Hero />
    <Services />
    <Residentials />
    <Commercials />
    <Achievements />
    <Reviews />
    <Partners />
  </div>
</template>
<script>
export default {
  components: {
    Hero: () => import(/* webpackChunkName: "home-hero" */ "./hero.vue"),
    Services: () =>
      import(/* webpackChunkName: "home-services" */ "./services.vue"),
    Achievements: () =>
      import(/* webpackChunkName: "home-achievements" */ "./achievements.vue"),
    Residentials: () =>
      import(
        /* webpackChunkName: "home-residentials" */ "../residentials/homePage.vue"
      ),
    Commercials: () =>
      import(
        /* webpackChunkName: "home-commercials" */ "../commercials/homePage.vue"
      ),
    Reviews: () =>
      import(/* webpackChunkName: "home-reviews" */ "../more/reviews.vue"),
    Partners: () =>
      import(/* webpackChunkName: "home-partners" */ "./partners.vue"),
  },
  computed: {},
};
</script>