window.Vue = require('vue').default;
import Swal from "sweetalert2";
import VTooltip from 'v-tooltip';

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

Vue.mixin({
    methods: {
        app_name() { return this.$page.props.config.app_name },
        app_url() { return this.$page.props.config.app_url },
        /**
         * usage this.asset("aaa") OR asset("aaa")
         * @param {string} resolveURI 
         */
        asset: function (resolveURI) {
            const asset_base = this.$page.props.config.asset_url;
            return (asset_base.slice(asset_base.length - 1) == '/' ? asset_base : asset_base + '/') + (resolveURI.charAt(0) == '/' ? resolveURI.slice(1) : resolveURI);
        },
        // alerts
        /**
         * 
         * @returns {isConfirmed: true, isDenied: false, isDismissed: false, value: true} - success
         * @returns {isConfirmed: false, isDenied: false, isDismissed: true, dismiss: "backdrop"} - failed
         * @returns {isConfirmed: false, isDenied: false, isDismissed: true, dismiss: "cancel"} - failed
         */
        swal_confirm: (question, icon, buttonText) => {
            return Swal.fire({
                title: question || "Are you sure?",
                icon: icon || "warning",
                showCancelButton: true,
                confirmButtonText: buttonText || "Yes",
                focusConfirm: false,
            });
        },
        success_alert: (_message) => {
            Toast.fire({
                icon: 'success',
                title: _message
            });
        },
        error_alert: (_message) => {
            Toast.fire({
                icon: 'error',
                title: _message
            });
        },
        info_alert: (_message) => {
            Toast.fire({
                icon: 'info',
                title: _message
            });
        },
        truncateText: (text, limit = 200, after = '...') => {
            let str = text.replace(/(<([^>]+)>)/gi, ""); // strip html tags
            str = str.trim();
            return str.length > limit ? str.substr(0, limit) + after : str;
        },
        currencyFormat: (amt) =>
            amt.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            }),
        stripTags: (str) => str.replace(/(<([^>]+)>)/gi, ""),
        // in view port
        isInViewport: ($ele) => {
            try {
                var elementTop = $ele.offset().top;
                var elementBottom = elementTop + $ele.outerHeight();

                var viewportTop = $(window).scrollTop();
                var viewportBottom = viewportTop + $(window).height();

                return elementBottom > viewportTop && elementTop < viewportBottom;
            } catch {
                return false;
            }
        },
        Pluralize: (word, amount) => (amount > 1 || amount == 0) ? `${word}s` : word,
        PageTitle(title) {
            const appName = this.$page.props.config.app_name || '';
            document.title = title ? `${title} | ${appName}` : appName;
        },

        LightenDarkenColor(col, amt) { // +amt for lighten, -amt for darken
            var usePound = false;
            if (col[0] == "#") {
                col = col.slice(1);
                usePound = true;
            }
            var num = parseInt(col, 16);
            var r = (num >> 16) + amt;
            if (r > 255) r = 255;
            else if (r < 0) r = 0;
            var b = ((num >> 8) & 0x00FF) + amt;
            if (b > 255) b = 255;
            else if (b < 0) b = 0;
            var g = (num & 0x0000FF) + amt;
            if (g > 255) g = 255;
            else if (g < 0) g = 0;
            return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
        },

        // metaPageTitle: (title) => {
        //     return title ? `${title} | ${this.$page.props.config.app_name}` : this.$page.props.config.app_name;
        // },
        // metaPageDescription: (web_description) => {
        //     return `${web_description || CONFIG.SETTINGS.web_description} - ${CONFIG.APP_NAME}`;
        // },
        // metaSeoKeywords: (seo_tags) => {
        //     return seo_tags || CONFIG.SETTINGS.seo_tags;
        // },
        objLength: (objs) => objs && objs != undefined ? Object.keys(objs).length : 0,
        dateFormat: (timestamp) => {
            if (!timestamp) return '';
            let d = new Date(Date.parse(timestamp));
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

            return `${da} ${mo}, ${ye}`;
        },
        dateTimeFormat: (timestamp) => {
            let d = new Date(Date.parse(timestamp));
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

            let time = new Intl.DateTimeFormat('en', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }).format(d);
            return `${da} ${mo}, ${ye} ${time}`;
        },
        initial: (name) => {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
            let initials = [...name.matchAll(rgx)] || [];
            return (
                (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();
        },
    },
});

Vue.filter('pluralize', (word, amount) => (amount > 1 || amount == 0) ? `${word}s` : word);

const ShortKey = require('vue-shortkey')
// add any custom shortkey config settings here
Vue.use(ShortKey, { prevent: ['input', 'textarea', 'iframe'] });
// tooltip
Vue.use(VTooltip);