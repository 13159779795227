<template>
  <section id="contact" class="contact">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 col-md-12 m-auto">
          <div class="section-title">
            <h2 class="text-center">Contact us</h2>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="info-box">
                <h3>We&#x2019;d love to hear from you</h3>
                <table>
                  <tr>
                    <td v-if="settings.whatsapp">
                      <a
                        :href="settings.whatsapp"
                        target="_blank"
                        class="whatsapp"
                        :title="settings.whatsapp"
                      >
                        <i class="fab fa-whatsapp"></i>
                      </a>
                    </td>
                    <td v-if="settings.facebook">
                      <a
                        :href="settings.facebook"
                        target="_blank"
                        class="facebook"
                        :title="settings.facebook"
                      >
                        <i class="fab fa-facebook"></i>
                      </a>
                    </td>
                    <td v-if="settings.instagram">
                      <a
                        :href="settings.instagram"
                        target="_blank"
                        class="instagram"
                        :title="settings.instagram"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </td>
                    <td v-if="settings.twitter">
                      <a
                        :href="settings.twitter"
                        target="_blank"
                        class="twitter"
                        :title="settings.twitter"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                    </td>
                    <td v-if="settings.youtube">
                      <a
                        :href="settings.youtube"
                        target="_blank"
                        class="youtube"
                        :title="settings.youtube"
                      >
                        <i class="fab fa-youtube"></i>
                      </a>
                    </td>
                    <td v-if="settings.linkedin">
                      <a
                        :href="settings.linkedin"
                        target="_blank"
                        class="linkedin"
                        :title="settings.linkedin"
                      >
                        <i class="fab fa-linkedin"></i>
                      </a>
                    </td>
                  </tr>
                  <tr style="font-size: 12px !important">
                    <td v-if="settings.whatsapp">
                      <span class="text-muted text-sm">Whatsapp</span>
                    </td>
                    <td v-if="settings.facebook">
                      <span class="text-muted text-sm">Facebook</span>
                    </td>
                    <td v-if="settings.instagram">
                      <span class="text-muted text-sm">Instagram</span>
                    </td>
                    <td v-if="settings.twitter">
                      <span class="text-muted text-sm">Twitter</span>
                    </td>
                    <td v-if="settings.youtube">
                      <span class="text-muted text-sm">Youtube</span>
                    </td>
                    <td v-if="settings.linkedin">
                      <span class="text-muted text-sm">Linkedin</span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="info-box">
                    <i class="fa fa-inbox"></i>
                    <h3>Email</h3>
                    <p>
                      <a
                        :href="`mailto:${settings.email_primary}`"
                        title="Email us"
                      >
                        {{ settings.email_primary }}
                      </a>
                      <span v-if="settings.email_secondary">, </span>
                      <a
                        :href="`mailto:${settings.email_secondary}`"
                        title="Email us"
                        class="ml-1"
                      >
                        {{ settings.email_secondary }}
                      </a>
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="info-box">
                    <i class="fa fa-phone-alt"></i>
                    <h3>Phone</h3>
                    <p>
                      <a
                        :href="`tel:+${settings.phone_primary}`"
                        title="Contact us"
                      >
                        {{ settings.phone_primary }}
                      </a>
                      <template v-if="settings.phone_secondary">
                        <span>, </span>
                        <a
                          :href="`tel:+${settings.phone_secondary}`"
                          title="Contact us"
                        >
                          {{ settings.phone_secondary }}
                        </a>
                      </template>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <form
                method="post"
                class="php-email-form"
                role="form"
                @submit.prevent="submit"
              >
                <div class="text-center mb-4">
                  <h3>Let&#x2019;s Connect</h3>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <TextBox
                      label="Name"
                      name="name"
                      placeholder="Enter name"
                      v-model="form.name"
                      :disabled="form.processing"
                      :error="form.errors.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <TextBox
                      label="Email"
                      name="email"
                      placeholder="Enter email"
                      v-model="form.email"
                      :disabled="form.processing"
                      :error="form.errors.email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <TextBox
                      label="Mobile number"
                      name="mobile"
                      placeholder="Enter mobile"
                      v-model="form.mobile"
                      :disabled="form.processing"
                      :error="form.errors.mobile"
                    />
                  </div>
                  <div class="col-lg-6">
                    <TextBox
                      label="Subject"
                      name="subject"
                      placeholder="Enter subject"
                      v-model="form.subject"
                      :disabled="form.processing"
                      :error="form.errors.subject"
                    />
                  </div>
                </div>
                <TextArea
                  label="Message"
                  name="message"
                  placeholder="Enter message"
                  v-model="form.message"
                  :disabled="form.processing"
                  :error="form.errors.message"
                />

                <div class="mt-4 text-center">
                  <button
                    class="btn btn-primary w-100"
                    type="submit"
                    :disabled="form.processing"
                  >
                    <div
                      class="spinner-border spinner-border-sm"
                      role="status"
                      v-if="form.processing"
                    ></div>
                    <span v-else>Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {
    TextArea: () =>
      import(
        /* webpackChunkName: "form-textarea" */ "@/components/form/textarea.vue"
      ),
  },
  data() {
    return {
      form: this.$inertia.form({
        name: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.$route("home.contact.submit"), {
        preserveScroll: true,
        onSuccess: () => {
          this.form.reset();
          this.form.clearErrors();
        },
      });
    },
  },
  computed: {
    settings() {
      return this.$page.props.config.settings;
    },
  },
};
</script>
<style lang="scss" scoped>
/*--------------------------------------------------------------
# Contact Us
--------------------------------------------------------------*/
.contact .info-box {
  background: var(--white);
  text-align: center;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  border-radius: var(--border-radius);
  padding: 20px 0 30px 0;
  margin-bottom: 30px;
  width: 100%;
}

.contact .info-box i {
  font-size: 32px;
  color: var(--primary);
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box h3 {
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: var(--white);
  padding: 30px;
  box-shadow: 0 0 38px 0 rgb(0 0 0 / 13%);
  border-radius: var(--border-radius);
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: var(--white);
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: var(--dark);
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  border-radius: var(--border-radius-more);
  outline: 0;
}

.facebook i {
  color: #3b5998 !important;
}

.instagram i {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  ) !important;
  -webkit-background-clip: text !important;
  /* Also define standard property for compatibility */
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.linkedin i {
  color: #0e76a8 !important;
}

.youtube i {
  color: #c4302b !important;
}

.whatsapp i {
  color: #25d366 !important;
}

table {
  width: 100%;
  margin: 20px 0;
  font-size: 32px;
  text-align: center;
}
</style>