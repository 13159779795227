<template>
  <section id="contact" class="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-md-12 m-auto">
          <form method="post" class="form" role="form" @submit.prevent="submit">
            <div class="section-title text-center">
              <h2 class="mb-3">
                <span>Want to work with us?</span>
              </h2>
              <p class="text-muted mb-2">
                We want specialists who are already experts in their chosen
                field and are ready to spend several years advancing the state
                of the art in it.
              </p>
            </div>
            <TextBox
              label="Name"
              name="name"
              placeholder="Enter name"
              v-model="form.name"
              :disabled="form.processing"
              :error="form.errors.name"
            />
            <div class="row">
              <div class="col-lg-6">
                <TextBox
                  label="Email"
                  name="email"
                  placeholder="Enter email"
                  v-model="form.email"
                  :disabled="form.processing"
                  :error="form.errors.email"
                />
              </div>
              <div class="col-lg-6">
                <TextBox
                  label="Mobile number"
                  name="mobile"
                  placeholder="Enter mobile"
                  v-model="form.mobile"
                  :disabled="form.processing"
                  :error="form.errors.mobile"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <TextBox
                  label="Education (Qualification)"
                  name="education"
                  placeholder="Enter education details"
                  v-model="form.education"
                  :disabled="form.processing"
                  :error="form.errors.education"
                  helper="Eg. Engineering, MBA, etc."
                />
              </div>
              <div class="col-lg-6">
                <TextBox
                  label="Experience (Years)"
                  name="experience"
                  placeholder="Enter number of years of experience"
                  v-model="form.experience"
                  :disabled="form.processing"
                  :error="form.errors.experience"
                  helper="Enter 0 if you have no experience"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="resume-img">Resume</label>
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="resume-img"
                  accept="pdf/*"
                  @change="updateResume"
                  ref="resume"
                  :disabled="form.processing"
                  :class="{ 'is-invalid': form.errors.resume }"
                />
                <label class="custom-file-label" for="resume-img">{{
                  resume.label
                }}</label>
                <transition name="form-error">
                  <div
                    class="error"
                    v-if="form.errors.resume"
                    v-text="form.errors.resume"
                  ></div>
                </transition>
              </div>
            </div>
            <div class="mt-4 text-center">
              <button
                class="btn btn-primary w-100"
                type="submit"
                :disabled="form.processing"
              >
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  v-if="form.processing"
                ></div>
                <span v-else>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      form: this.$inertia.form({
        name: "",
        email: "",
        mobile: "",
        education: "",
        experience: "",
        resume: null,
      }),
      resume: {
        label: "Upload resume",
      },
    };
  },
  methods: {
    submit() {
      this.form.post(this.$route("home.careers.submit"), {
        preserveScroll: true,
        onSuccess: () => {
          this.form.reset();
          this.form.clearErrors();
          this.resume.label = "Upload resume";
        },
      });
    },
    updateResume(event) {
      this.resume.label = event.target.files[0].name;
      this.form.resume = event.target.files[0];
    },
  },
  computed: {
    settings() {
      return this.$page.props.config.settings;
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  background: var(--white);
  padding: 30px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
</style>