<template>
  <section v-if="!noData">
    <InfiniteScroll @isvisible="getData" :once="true">
      <div class="container" ref="wrapper">
        <div class="section-title">
          <h2>
            <span>{{ title }}</span>
            <inertia-link
              :href="$route('home.commercial.index')"
              class="
                btn btn-dark
                d-block
                float-none float-md-right
                mt-3 mt-md-0
              "
              >View all</inertia-link
            >
          </h2>
        </div>
        <div class="row" v-if="loading">
          <div class="col-lg-6 mb-4" v-for="i in 2" :key="i">
            <Item />
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-6 mb-4" v-for="item in data" :key="item.id">
            <Item :data="item" />
          </div>
        </div>
      </div>
    </InfiniteScroll>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Rental properties",
    },
    exclude: null,
  },
  components: {
    Item: () => import(/* webpackChunkName: "commercial-item" */ "./item.vue"),
  },
  data() {
    return {
      loading: true,
      data: [],
      noData: false,
    };
  },
  methods: {
    getData() {
      axios
        .get(this.$route("api.commercial.homepage"), {
          params: {
            exclude: this.exclude,
          },
        })
        .then((response) => {
          this.data = response.data.data;
          this.noData = response.data.data.length === 0;
          setTimeout(() => {
            this.loading = false;
          }, 200);
        });
    },
  },
};
</script>