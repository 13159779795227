<template>
  <section>
    <div class="container">
      <div class="section-title text-center">
        <h2>
          <span>Villas</span>
        </h2>
      </div>
      <SearchBar
        v-model="form.search"
        @onSubmit="searchSubmit"
        @onCleared="searchSubmit"
      />
      <div class="row" v-if="data.data && data.data.length">
        <div
          class="col-xl-3 col-xl-4 col-md-6 col-lg-4 mb-4"
          v-for="villa in data.data"
          :key="villa.id"
        >
          <Item :data="villa" />
        </div>
      </div>
      <NoDataFound v-else title="No villas found" />
      <Pagination :rawData="data.data" />
    </div>
  </section>
</template>
<script>
export default {
  components: {
    Item: () => import(/* webpackChunkName: "villa-item" */ "./item.vue"),
    SearchBar: () =>
      import(
        /* webpackChunkName: "components-searchbar" */ "@/components/searchBar.vue"
      ),
  },
  props: {
    data: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        search: "",
        page: 1,
      }),
    };
  },
  mounted() {
    this.form.search = this.$route().params.search || null;
  },
  methods: {
    searchSubmit() {
      this.form.page = 1;
      this.form.get(this.$route("home.villa.index"));
    },
  },
};
</script>