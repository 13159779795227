<template>
  <section>
    <div class="container">
      <div class="section-title">
        <h2 class="text-center">
          <span>About us</span>
        </h2>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <img
            v-lazy="asset('images/logo.png')"
            :alt="app_name()"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-6">
          <p>
            Sky Reality is an independent real estate advisor located in Pune.
            Sky Reality has vast real estate expertise and experience in Pune,
            and we want to be your first port of contact if you are seeking to
            buy a house in the city. Sky Reality, formed by a creative and
            innovative entrepreneur, promises to leverage its considerable
            knowledge of the real estate industry to assist anybody looking to
            buy a home in Pune.
          </p>
          <div>
            <h3>Our Vision</h3>
            <p>
              Our objective is to rethink the real estate industry and become
              Pune's foremost service provider. We attempt to build creative
              collaborations based on openness and mutual trust in order to
              foster long-term client relationships. We are not just expanding;
              we are also adhering to the ideals that have served our company
              and clients well over the years.
            </p>
            <h3>Our Mission</h3>
            <p>
              We plan to achieve our goal by establishing a joyful,
              high-quality, results-driven work environment that develops
              long-term relationships. Our performance is measured by the
              outcomes we provide to our clients. Our ethics are founded on our
              dedication to providing excellent client service.
            </p>
            <h3>Our Fundamental Principles</h3>
            <p>
              Sky Reality's core principles of communication, devotion, and
              excellent customer service pervade all they do. We pay special
              attention to our customers' demands and tackle their real estate
              needs holistically. We take pride in our consistent dedication to
              increasing the value of our clients' real estate holdings.
            </p>
          </div>
        </div>
      </div>

      <template v-if="team.length">
        <div class="section-title">
          <h2 class="text-center">
            <span>Our Team</span>
          </h2>
        </div>
        <div class="card mb-3" v-for="(person, i) in team" :key="i">
          <div class="row no-gutters">
            <div class="col-md-4">
              <img v-lazy="person.avatar" class="card-img" :alt="person.name" />
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h3 class="card-title mb-0" v-text="person.name"></h3>
                <p class="text-muted" v-text="person.designation"></p>
                <p
                  class="card-text text-justify"
                  v-html="person.description"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    team: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // Journey: () => import(/* webpackChunkName: "shared-journey" */ "./journey"),
  },
};
</script>