<template>
  <div>
    <Header />
    <main :class="{ 'is-fixed': isFixed }">
      <slot />
    </main>
    <Footer />
    <EnquiryForm />
    <WhatsappFab />
  </div>
</template>
<script>
const seo = {
  title: defaultSeo.title,
  description: defaultSeo.description,
  keywords: defaultSeo.keywords,
  image: defaultSeo.image,
  robots: defaultSeo.robots,
  "og:site_name": defaultSeo["og:site_name"],
  "og:type": defaultSeo["og:type"],
  "og:locale": defaultSeo.locale,
  "og:url": defaultSeo["og:url"],
};

export default {
  components: {
    Header: () => import(/* webpackChunkName: "header" */ "./header.vue"),
    Footer: () => import(/* webpackChunkName: "footer" */ "./footer.vue"),
    EnquiryForm: () =>
      import(/* webpackChunkName: "enquiry-form" */ "./enquiryForm.vue"),
    WhatsappFab: () =>
      import(
        /* webpackChunkName: "whatsapp-fab" */ "../components/whatsappFab.vue"
      ),
  },
  watch: {
    "$page.props.flash": {
      immediate: true,
      handler(data) {
        if (data.use_sweetalert) {
          if (data.success_msg) {
            this.success_alert(data.success_msg);
          }
          if (data.error_msg) {
            this.error_alert(data.error_msg);
          }
          if (data.info_msg) {
            this.info_alert(data.info_msg);
          }
        }
      },
    },
    "$page.props.seoData": {
      immediate: true,
      handler(data) {
        if (data) {
          this.meta = { ...seo, ...data };
        } else {
          this.meta = seo;
        }
      },
    },
  },
  data() {
    return {
      isFixed: false,
      meta: seo,
    };
  },
  mounted() {
    this.$root.$on("topNavPosition", (isFixed) => {
      this.isFixed = isFixed;
    });
  },
  metaInfo() {
    return {
      title: this.meta.title,
      meta: [
        {
          vmid: "keyword",
          name: "keyword",
          content: this.meta.keywords,
        },
        // titles
        {
          vmid: "og:title",
          name: "og:title",
          content: this.meta.title,
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.meta.title,
        },
        // descriptions
        {
          vmid: "description",
          name: "description",
          content: this.meta.description,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: this.meta.description,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.meta.description,
        },
        // images
        {
          vmid: "og:image",
          name: "og:image",
          content: this.meta.image,
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.meta.image,
        },
        // other
        {
          vmid: "og:type",
          name: "og:type",
          content: this.meta["og:type"],
        },
        {
          vmid: "og:url",
          name: "og:url",
          content: this.meta["og:url"],
        },
        {
          vmid: "canonical",
          name: "canonical",
          content: this.meta["og:url"],
        },
        {
          vmid: "og:site_name",
          name: "og:site_name",
          content: this.meta["og:site_name"],
        },

        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: this.meta["og:url"],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
main {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  &.is-fixed {
    padding-top: calc(var(--top-nav-height, 80px) + 16px);
  }
}
</style>