/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
Vue.config.productionTip = false;

Vue.prototype.$sideNavOpen = true;
Vue.prototype.$route = route;

import './mixins/helperMixins';
import './directives';

import VueLazyload from 'vue-lazyload';

// layouts
import HomeLayout from "./Layouts/home";
// svgs
Vue.component("NoDataFoundSvg", () => import("./components/svg/noDataFoundSvg"));
Vue.component("NoDataFound", () => import("./components/Errors/noDataFound"));
// components
Vue.component("InfiniteScroll", () => import("./components/infiniteScroll.vue"));
Vue.component("Pagination", () => import("./components/Pagination/Index.vue"));
Vue.component("TextBox", () => import("./components/form/textbox"));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import { createInertiaApp } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia'
import { InertiaProgress } from '@inertiajs/progress';
import Meta from 'vue-meta';

Vue.use(Meta);

Vue.use(VueLazyload, {
    loading: '/images/loading.png',
    attempt: 1,
    observer: true,
});

InertiaProgress.init({
    // The color of the progress bar.
    color: "var(--primary)",

    delay: 250,

    // Whether the NProgress spinner will be shown.
    showSpinner: false,
});

Inertia.on('success', (event) => {
    // scroll to top
    window.scrollTo(0, 0);
});

// store.dispatch("setTheme");
createInertiaApp({
    resolve: name => require(`./Pages/${name}`),
    resolve: name => {
        const page = require(`./Pages/${name}`).default
        if (page.layout === undefined && !name.startsWith('Admin/')) {
            page.layout = HomeLayout
        }
        return page
    },
    setup({ el, app, props }) {
        new Vue({
            render: h => h(app, props),
        }).$mount(el)
    },
});