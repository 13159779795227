<template>
  <section v-if="!noData">
    <InfiniteScroll @isvisible="getData" :once="true">
      <div class="container" ref="wrapper">
        <div class="section-title">
          <h2>
            <span>{{ title }}</span>
            <inertia-link
              :href="$route('home.residential.index')"
              class="
                btn btn-dark
                d-block
                float-none float-md-right
                mt-3 mt-md-0
              "
              >View all</inertia-link
            >
          </h2>
        </div>
        <div class="row" v-if="loading">
          <div
            class="col-xl-3 col-xl-4 col-md-6 col-lg-4 mb-4"
            v-for="i in 3"
            :key="i"
          >
            <Item />
          </div>
        </div>
        <div class="row" v-else>
          <div
            class="col-xl-3 col-xl-4 col-md-6 col-lg-4 mb-4"
            v-for="item in data"
            :key="item.id"
          >
            <Item :data="item" />
          </div>
        </div>
      </div>
    </InfiniteScroll>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Buy/Sell Properties",
    },
    exclude: null,
  },
  components: {
    Item: () => import(/* webpackChunkName: "residential-item" */ "./item.vue"),
  },
  data() {
    return {
      loading: true,
      data: [],
      noData: false,
    };
  },
  methods: {
    getData() {
      axios
        .get(this.$route("api.residential.homepage"), {
          params: {
            exclude: this.exclude,
          },
        })
        .then((response) => {
          this.data = response.data.data;
          this.noData = response.data.data.length === 0;
          setTimeout(() => {
            this.loading = false;
          }, 200);
        })
        .catch(() => {
          this.loading = false;
          this.noData = true;
        });
    },
  },
};
</script>