<template>
  <div>
    <div class="gallary">
      <div class="item" v-for="(image, i) in images" :key="i">
        <img
          v-lazy="image"
          role="link"
          class="img-fluid"
          @click.prevent.stop="$root.$emit('OpenGallery', image)"
          style="cursor: pointer"
        />
      </div>
    </div>
    <ImagesGallery :data="images" />
  </div>
</template>
<script>
export default {
  components: {
    ImagesGallery: () =>
      import(
        /* webpackChunkName: "images-gallery" */ "@/components/ImagesGallery"
      ),
  },
  computed: {
    images() {
      return this.$page.props.data.data.floor_plans;
    },
  },
};
</script>
<style lang="scss" scoped>
.gallary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 1rem;
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .item {
    box-shadow: 0 0.375rem 1.2rem 0 rgb(209 209 209 / 13%);
    border: 1px solid var(--lighter);
    border-radius: var(--border-radius);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>