<template>
  <section class="achievement-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <h2 class="mb-3 font-weight-bold">Our Achievements</h2>
          <p class="mb-4 text-muted sub-heading">
            We have been assisting our clients in finding their happy location to live or work for over 6 years. Our methodology, along with a comprehensive grasp of the market, has enabled us to provide our clients with not only qualified advice, but also personalized resources.
          </p>

          <div class="row">
            <div class="col-lg-4 col-md-6 mb-3 mb-lg-0">
              <div class="achievement-box">
                <img
                  v-lazy="
                    asset(
                      'images/helpers/icons8-smiling-face-with-smiling-eyes-48.png'
                    )
                  "
                  alt=""
                  class="img-fluid mb-2"
                />
                <div class="count">1,000</div>
                <p class="title mb-0">Happy Clients</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3 mb-lg-0">
              <div class="achievement-box">
                <img
                  v-lazy="asset('images/helpers/icons8-yard-sale-48.png')"
                  class="img-fluid mb-2"
                />
                <div class="count">999</div>
                <p class="title mb-0">Properties sold</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="achievement-box">
                <img
                  v-lazy="asset('images/helpers/icons8-stadium-48.png')"
                  class="img-fluid mb-2"
                />
                <div class="count">50,000</div>
                <p class="title mb-0">Sq.ft of properties sold</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      v-lazy="asset('images/helpers/achievements.jpg')"
      class="bg-cover-img"
    />
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.achievement-box {
  padding: 2rem;
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  .title {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--dark);
    opacity: 0.8;
  }
  .count {
    font-size: 2.3rem;
    font-weight: bold;
    font-family: var(--font-family-monospace);
    margin-bottom: 0.4rem;
  }
}

.achievement-wrapper {
  position: relative;
  min-height: min(700px, 90vh);
  display: flex;
  align-items: center;
  .bg-cover-img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }
  .sub-heading {
    max-width: 60%;
  }
  @media (max-width: 768px) {
    .sub-heading {
      max-width: 100%;
    }

    .bg-cover-img {
      display: none;
    }
  }
}
</style>