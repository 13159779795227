<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-7 mb-3 mb-lg-0">
          <div
            class="swiper-container"
            ref="swiper-container"
            style="overflow: hidden"
          >
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper" v-if="data.data.images.length > 0">
              <!-- Slides -->
              <div
                class="text-center swiper-slide"
                v-for="(image, i) in data.data.images"
                :key="i"
              >
                <img
                  v-lazy="image"
                  :alt="data.data.name"
                  role="link"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="swiper-wrapper" v-else>
              <div class="text-center swiper-slide">
                <img
                  v-lazy="image"
                  :alt="data.data.thumbnail"
                  role="link"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="swiper-pagination" ref="swiper-pagination"></div>
            <div class="swiper-button-prev" ref="swiper-button-prev"></div>
            <div class="swiper-button-next" ref="swiper-button-next"></div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-5">
          <h2>{{ data.data.name }}</h2>
          <p class="text-muted">
            <i class="fa fa-map-marker-alt"></i> {{ data.data.location }}
          </p>
          <a
            :href="data.data.video"
            class="btn btn-danger-outline"
            target="_blank"
            v-if="data.data.video"
          >
            <i class="fab fa-youtube mr-1"></i> YouTube
          </a>

          <p class="text-muted mb-0 mt-4">Budget:</p>
          <h1>
            <span>{{ data.data.budget }}</span>
          </h1>
          <div class="mt-3 mt-lg-4">
            <a
              :href="data.data.brochure"
              class="btn btn-outline-danger m-2"
              download=""
              v-if="data.data.brochure"
            >
              Download Brochure
              <i class="fa fa-cloud-download-alt ml-1 d-none d-lg-inline"></i>
            </a>

            <a
              href="#"
              class="btn btn-primary m-2 mr-0"
              @click.prevent="
                $root.$emit('openEnquiryForm', {
                  type: 'villa',
                  type_id: data.data.slug,
                })
              "
            >
              Enquire Now
              <small class="fa fa-arrow-right ml-1 d-none d-lg-inline"></small>
            </a>
          </div>
        </div>
        <div class="col-12 mt-4">
          <h3>Details</h3>
          <p v-html="data.data.description_raw"></p>
        </div>
      </div>
      <RelatedVillas :exclude="data.data.slug" :title="'Related Villas'" />
    </div>
  </section>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css/bundle";

Swiper.use([Navigation, Pagination]);

export default {
  props: {
    data: {
      type: [Object, Array],
    },
  },
  components: {
    RelatedVillas: () =>
      import(/* webpackChunkName: "home-villas" */ "./homePage.vue"),
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      new Swiper(this.$refs["swiper-container"], {
        speed: 400,
        loop: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
        // spaceBetween: 30,
        pagination: {
          el: this.$refs["swiper-pagination"],
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper-container {
  height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>