<template>
  <section>
    <div class="container">
      <div class="section-title">
        <h2 class="text-center">
          <span>Our Journey</span>
        </h2>
      </div>
    </div>
    <div class="container px-1 px-lg-5">
      <div class="row journey-single" v-for="(item, i) in data" :key="i">
        <div class="col-2 year">
          <span v-text="item.year"></span>
        </div>
        <div class="col-10 content">
          <span v-html="item.content"></span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          year: "2019",
          content: "Riseonic real estate established in December",
        },
        {
          year: "2020",
          content:
            "Planned road map for Riseonic & started rappo with genuine property buyers & investors. Certificate Of Appreciation From Mital Brothers. <b>Valuable Contributior</b> at <b>Solatire Group</b>",
        },
        {
          year: "2021",
          content:
            "We become one of the reputed and trustworthy Real Estate firm. Recived <b>Record Breaking Award</b> at Channel partner success - Gala 2021 Award <b>Empire Square</b>. Certificate of appreciation for promising channel <b>partner by Engineers Horizon</b>.",
        },
        {
          year: "2022",
          content:
            "As this year we achieved <b>certificate of appreciation</b> from <b>Welworth Reality</b>, We've established a strategy to become the top channel partner in the Pune region for commercial sector and villas this year as we continue to reach our award-winning goals.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.journey-single {
  padding: 1rem;
}

.journey-single .year {
  text-align: right;
}

.journey-single .year span {
  padding: 0.4rem;
  background: var(--lighter);
  font-family: var(--font-family-monospace);
  font-weight: 600;
}

.journey-single .content {
  font-family: var(--font-family-primary);
  font-size: 1.2rem;
}
</style>