<template>
  <div>
    <iframe
      :src="url"
      width="100%"
      height="600"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</template>
<script>
export default {
  computed: {
    url() {
      return this.$page.props.data.data.brochure;
    },
  },
};
</script>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 600px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  border: 0;

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 576px) {
    height: 300px;
  }
}
</style>