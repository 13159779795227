<template>
  <section v-if="!noData">
    <InfiniteScroll @isvisible="getData" :once="true">
      <div class="container">
        <div class="section-title text-center">
          <h2 class="mb-0">
            <span>Reviews</span>
          </h2>
          <p class="text-muted mb-2">What our clients say about us!</p>
        </div>

        <div class="reviews">
          <div class="position-relative mx-md-2 px-md-5 px-3">
            <div class="swiper-button-prev" ref="swiper-button-prev"></div>
            <div class="swiper-button-next" ref="swiper-button-next"></div>

            <div class="swiper" ref="swiper-container">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper">
                <!-- Slides -->
                <div
                  class="swiper-slide py-3"
                  v-for="item in data"
                  :key="item.id"
                >
                  <div class="item mx-3">
                    <div class="row">
                      <div class="col-md-8 text-right">
                        <img
                          :src="item.property_img"
                          class="img-fluid property-img"
                        />
                      </div>
                      <div class="col-md-4 d-flex align-items-center">
                        <div class="review-data">
                          <i class="fa fa-quote-right"></i>
                          <blockquote class="text-muted">
                            "{{ item.message }}"
                          </blockquote>
                          <div class="meta">
                            <div class="avatar">
                              <img
                                :src="item.avatar"
                                alt=""
                                class="avatar-img"
                              />
                            </div>
                            <div class="name-wrapper">
                              <div class="name font-weight-bold">
                                {{ item.name }}
                              </div>
                              <div
                                class="company text-dim"
                                v-if="item.company_name"
                              >
                                &#8212; {{ item.company_name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfiniteScroll>
  </section>
</template>
<script>
import Swiper, { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css/bundle";

Swiper.use([Navigation]);

export default {
  data() {
    return {
      data: [],
      loading: true,
      noData: false,
    };
  },
  methods: {
    getData() {
      axios
        .get(this.$route("api.reviews"))
        .then((response) => {
          this.data = response.data.data;
          this.noData = response.data.data.length === 0;
          setTimeout(() => {
            this.loading = false;
            this.initSwiper();
          }, 200);
        })
        .catch(() => {
          this.loading = false;
          this.noData = true;
        });
    },
    initSwiper() {
      new Swiper(this.$refs["swiper-container"], {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.property-img {
  border-radius: var(--border-radius-more);
  box-shadow: var(--box-shadow);
  background: var(--card-color);
  object-fit: cover;
  height: 430px;
  width: 620px;
  @media (max-width: 768px) {
    height: 300px;
    width: 100%;
  }
}

.review-data {
  background: var(--card-color);
  padding: 1.5rem 1.2rem;
  box-shadow: var(--box-shadow-sm);
  border-radius: var(--border-radius-more);
  margin-left: -8rem;
  position: relative;
  .fa-quote-right {
    position: absolute;
    top: -1.9rem;
    right: 1.6rem;
    font-size: 3rem;
    color: var(--primary);
  }
  blockquote {
    font-style: italic;
    letter-spacing: 1px;
  }
  .meta {
    display: flex;
    align-items: center;
    gap: 1rem;
    .avatar-img {
      height: 60px;
      width: 60px;
      border-radius: var(--border-radius-more);
      box-shadow: var(--box-shadow);
      object-fit: cover;
    }
  }
}

@media (max-width: 768px) {
  .property-img {
    height: 200px;
    width: 100%;
  }

  .review-data {
    margin-left: 0;
  }
}
</style>