<template>
  <div class="item" v-if="loading" style="pointer-events: none">
    <div
      class="preLoaderPlaceholder mb-0 w-100"
      style="
        height: inherit;
        min-height: inherit;
        border-radius: var(--border-radius-more);
      "
    ></div>
  </div>
  <inertia-link :href="url" class="item" v-else>
    <img v-lazy="data.thumbnail" class="bg-img" />
    <span class="box-overlay"></span>
    <div class="badges">
      <span class="badge badge-success" v-if="data.has_rera">RERA</span>
      <span v-else></span>
      <span class="badge badge-primary">{{ data.category.name }}</span>
    </div>
    <div class="left-wrapper">
      <span class="badge badge-danger mr-2" v-if="data.for_sale">For Sale</span>
      <span class="badge badge-danger" v-if="data.for_rent">For Rent</span>
      <div class="mb-2"></div>
      <div class="location mb-1">
        <i class="fa fa-map-marker-alt"></i> {{ data.location }}
      </div>
      <h3 class="title wrap-text-1 mb-1">{{ data.name }}</h3>
      <div class="builder mb-2 mb-md-3">
        {{ data.developer_name }}
        <i class="fa fa-check-circle text-success"></i>
      </div>
      <div class="meta">
        <span>{{ data.sqft }}</span>
        <span class="meta-divider">|</span>
        <span>{{ data.height }}</span>
        <span class="meta-divider">|</span>
        <span>{{ data.size }}</span>
      </div>
    </div>
    <div class="right-wrapper">
      <div class="price-wrapper">
        <span class="price-helper">MIN: </span>
        <span class="price">{{ data.min_price }}</span>
      </div>
      <div class="price-wrapper">
        <span class="price-helper">MAX: </span>
        <span class="price">{{ data.max_price }}</span>
      </div>
    </div>
  </inertia-link>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    loading() {
      return !(
        typeof this.data === "object" && this.objLength(this.data) !== 0
      );
    },
    url() {
      return this.$route("home.commercial.show", { slug: this.data.slug });
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  --height: 600px;
  min-height: var(--height);
  width: 100%;
  border-radius: var(--border-radius-more);
  padding: 1.2rem;
  position: relative;
  color: var(--white);
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  transition: var(--transition);
  overflow: hidden;
  &:hover {
    background-size: 140%;
    box-shadow: var(--box-shadow);
    .bg-img {
      transform: scale(1.1);
    }
  }
  .box-overlay {
    position: absolute;
    background-image: linear-gradient(
      180deg,
      rgba(31, 27, 45, 0) 0%,
      rgba(31, 27, 45, 0.1) 26.56%,
      rgba(31, 27, 45, 0.28) 42.71%,
      rgba(31, 27, 45, 0.48) 56.77%,
      rgba(31, 27, 45, 0.68) 72.4%,
      rgba(31, 27, 45, 0.85) 86.98%,
      rgba(31, 27, 45, 0.94) 100%
    );
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: inherit;
  }
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    transition: all 1s cubic-bezier(0.2, 1, 0.22, 1);
  }

  @media (max-width: 767px) {
    --height: 420px;
  }

  @media (max-width: 576px) {
    --height: 400px;
    border-radius: var(--border-radius);
    padding: 0.8rem;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.65rem;
    .left-wrapper,
    .right-wrapper {
      flex: unset;
      width: 100%;
      text-align: left;
    }
    .right-wrapper {
      display: flex;
      justify-content: space-between;
    }
    h3 {
      font-size: 1.46rem;
    }
  }
}

.left-wrapper,
.right-wrapper {
  position: relative;
  z-index: 2;
}

.left-wrapper {
  flex: 1;
  .location {
    opacity: 0.8;
    font-size: 0.9rem;
  }
  .builder {
    font-weight: 500;
    font-size: 92%;
  }
  .meta {
    opacity: 0.8;
    font-size: 0.9rem;
  }
  .meta-divider {
    padding: 0 4px;
    font-size: 84%;
  }
  @media (max-width: 768px) {
    .location {
      font-size: 0.8rem;
    }

    .builder {
      font-size: 80%;
    }

    .meta {
      font-size: 0.8rem;
    }

    .meta-divider {
      font-size: 70%;
    }
  }
}

.right-wrapper {
  text-align: right;
}

.price-wrapper .price-helper {
  opacity: 0.8;
  font-size: 0.8rem;
  margin-right: 5px;
}

.price-wrapper .price {
  font-family: var(--font-family-primary);
  font-size: 1.6rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .price-wrapper .price-helper {
    font-size: 0.7rem;
  }

  .price-wrapper .price {
    font-size: 1.2rem;
  }
}

.badges {
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  right: 1.2rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .badge {
    font-size: 0.9rem;
    font-weight: 500;
  }
}
</style>