import { render, staticRenderFns } from "./floorPlans.vue?vue&type=template&id=ed872928&scoped=true&"
import script from "./floorPlans.vue?vue&type=script&lang=js&"
export * from "./floorPlans.vue?vue&type=script&lang=js&"
import style0 from "./floorPlans.vue?vue&type=style&index=0&id=ed872928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed872928",
  null
  
)

export default component.exports