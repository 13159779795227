<template>
  <div class="box" v-if="loading">
    <div class="img preLoaderPlaceholder"></div>
    <small class="preLoaderPlaceholder p-2 w-25"></small>
    <div class="preLoaderPlaceholder w-100"></div>
    <div>
      <span class="preLoaderPlaceholder p-2 mb-2 w-75"></span>
      <span class="preLoaderPlaceholder p-2 mb-3 w-50"></span>
    </div>
    <div class="price-wrapper pt-1 mt-1">
      <div class="d-flex flex-column" style="flex: 1">
        <span class="preLoaderPlaceholder p-3 mb-2 w-50"></span>
        <span class="preLoaderPlaceholder p-2 mb-0 w-25"></span>
      </div>
      <span
        class="preLoaderPlaceholder p-0 mb-0"
        style="height: 2.5rem; width: 2.5rem; border-radius: 50%"
      ></span>
    </div>
  </div>
  <div class="box" v-else @click.prevent="$inertia.get(url)">
    <inertia-link :href="url" @click.stop="">
      <div class="img">
        <img v-lazy="data.thumbnail" :alt="data.name" class="img-fluid" />
      </div>
      <small class="date">
        <i class="fa fa-map-marker-alt"></i> {{ data.location }}
      </small>
      <h3 class="title wrap-text-2">
        {{ data.name }}
      </h3>
    </inertia-link>
    <p class="mb-3 wrap-text-2 text-muted">
      {{ data.description }}
    </p>
    <div class="price-wrapper pt-1">
      <div class="d-flex flex-column">
        <span class="price">
          {{ data.budget }}
        </span>
        <span class="helper-text">Budget</span>
      </div>
      <inertia-link :href="url" class="arrow-btn" @click.stop="">
        <i class="fa fa-arrow-right"></i>
      </inertia-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    loading() {
      return !(
        typeof this.data === "object" && this.objLength(this.data) !== 0
      );
    },
    url() {
      return this.$route("home.villa.show", { slug: this.data.slug });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: var(--border-radius);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  cursor: pointer;

  &:hover {
    .title {
      color: var(--primary);
    }
    .img img {
      transform: scale(1.2);
    }
  }
}

.img {
  overflow: hidden;
  margin: -30px -30px 15px -30px;
  position: relative;
  height: 200px;
  img {
    transition: all 1s cubic-bezier(0.2, 1, 0.22, 1);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .badge {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 12px;
  }
  @media (max-width: 992px) {
    height: 300px;
  }
}
.date {
  font-weight: 600;
  color: var(--light-grey);
  display: block;
  margin-bottom: 10px;
}
.title {
  font-size: 24px;
  color: var(--dark);
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  transition: 0.3s;
}
.price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-family: var(--font-family-primary);
  font-weight: bold;
  font-size: 1.4rem;
}

.helper-text {
  text-transform: uppercase;
  font-size: 78%;
  font-weight: 500;
  opacity: 0.6;
}

.arrow-btn {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lighter);
  color: var(--dark);
  border-radius: 50%;
  transition: 0.3s;
}

.arrow-btn:hover {
  background: var(--primary-with-more-opacity);
  color: var(--primary-dark);
}
</style>