<template>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-11 m-auto">
          <div class="section-title text-center">
            <h2>
              <span>Rental properties</span>
            </h2>
          </div>
          <div class="row">
            <div class="col-xl-2">
              <Filters />
            </div>
            <div class="col">
              <div class="mb-4 d-flex">
                <SearchBar
                  v-model="form.search"
                  @onSubmit="searchSubmit"
                  @onCleared="searchSubmit"
                  placeholder="Search in all rental properties"
                  class="mb-0 w-100"
                  style="margin-bottom: 0 !important"
                />
                <button
                  type="button"
                  class="btn btn-light ml-2 d-inline d-xl-none"
                  @click.prevent="$root.$emit('openFilterForm')"
                >
                  <i class="fa fa-filter"></i>
                </button>
              </div>
              <div class="row" v-if="data.data && data.data.length">
                <div
                  class="col-xl-4 col-lg-6 mb-4"
                  v-for="item in data.data"
                  :key="item.id"
                >
                  <Item :data="item" />
                </div>
              </div>
              <NoDataFound v-else title="No rental properties found" />
              <Pagination :rawData="data.data" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {
    Item: () => import(/* webpackChunkName: "commercial-item" */ "./item.vue"),
    SearchBar: () =>
      import(
        /* webpackChunkName: "components-searchbar" */ "@/components/searchBar.vue"
      ),
    Filters: () =>
      import(
        /* webpackChunkName: "components-filters" */ "@/components/filters.vue"
      ),
  },
  props: {
    data: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        search: "",
        page: 1,
      }),
    };
  },
  mounted() {
    this.form.search = this.$route().params.search || null;
  },
  methods: {
    searchSubmit() {
      this.form.page = 1;
      this.form.get(this.$route("home.commercial.index"));
    },
  },
};
</script>