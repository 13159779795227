<template>
  <div>
    <h3>What is RERA?</h3>
    <p class="mb-4">
      The Real Estate (Regulation and Development) Act, 2016 is Act of the
      Parliament of India which seeks to protect buyers as well as help boost
      investments in the real estate industry. The Act came into force from 1
      May 2016.
    </p>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Phase</th>
          <th>Status</th>
          <th>Number</th>
          <th>Registration date</th>
          <th>Validity date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in rera" :key="i">
          <td>{{ item.phase }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.number }}</td>
          <td>{{ item.registration_date }}</td>
          <td>{{ item.validity_date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  computed: {
    rera() {
      return this.$page.props.data.data.rera;
    },
  },
};
</script>