<template>
  <div class="item" v-if="loading">
    <div class="img">
      <div class="img-fluid preLoaderPlaceholder m-0 p-0"></div>
    </div>
    <div class="data">
      <small class="date">
        <span class="preLoaderPlaceholder m-0 p-2 w-25"></span>
      </small>
      <span class="preLoaderPlaceholder mb-1"></span>
      <div class="builder text-muted mb-3" style="font-size: 85%">
        <span class="preLoaderPlaceholder m-0 p-2 w-50"></span>
      </div>
      <div class="price mb-3">
        <span class="preLoaderPlaceholder m-0 p-3 w-75"></span>
      </div>
      <div class="meta d-flex">
        <span
          class="preLoaderPlaceholder m-0 p-2 w-25 mr-2"
          v-for="i in 3"
          :key="i"
        ></span>
      </div>
    </div>
  </div>
  <div class="item" @click.prevent="$inertia.get(url)" v-else>
    <inertia-link :href="url" @click.stop="">
      <div class="img">
        <span class="badge badge-success" v-if="data.has_rera">RERA</span>
        <img v-lazy="data.thumbnail" class="img-fluid" />
      </div>
    </inertia-link>
    <div class="data">
      <small class="date">
        <i class="fa fa-map-marker-alt"></i> {{ data.location }}
      </small>
      <inertia-link :href="url" @click.stop="">
        <h3 class="title wrap-text-2 mb-1">
          {{ data.name }}
        </h3>
      </inertia-link>
      <div class="builder text-muted mb-3" style="font-size: 85%">
        {{ data.developer_name }}
        <i class="fa fa-check-circle text-success"></i>
      </div>
      <div class="price mb-3">
        <small class="fa fa-tag text-muted"></small>
        {{ data.min_price }} <small class="text-muted">to</small>
        {{ data.max_price }}
      </div>
      <div class="meta wrap-text-1">
        <template v-for="(config, i) in data.configuration_types">
          <span :key="i">{{ config }}</span>
          <span
            class="meta-divider"
            :key="config + '-divider-' + i"
            v-if="!lastItem(i)"
            >&bullet;</span
          >
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    lastItem(i) {
      return i === this.data.configuration.length - 1;
    },
  },
  computed: {
    loading() {
      return !(
        typeof this.data === "object" && this.objLength(this.data) !== 0
      );
    },
    url() {
      return this.$route("home.residential.show", { slug: this.data.slug });
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  cursor: pointer;
  &:hover {
    .title {
      color: var(--primary);
    }
  }
}

.img {
  position: relative;
  overflow: hidden;
  .badge {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
    box-shadow: var(--box-shadow);
    font-size: 0.9rem;
  }
  .img-fluid {
    height: 240px;
    width: 100%;
    border-radius: var(--border-radius-more);
    object-fit: cover;
    object-position: center;
  }
}
.date {
  font-weight: 600;
  color: var(--light-grey);
  display: block;
  margin-bottom: 10px;
}

.data {
  padding: 0.8rem 1rem;
}

.title {
  font-size: 24px;
  color: var(--dark);
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  transition: 0.3s;
  &:hover {
    color: var(--primary-dark);
  }
}

.price {
  font-size: 1.2rem;
  font-family: var(--font-family-primary);
  font-weight: bold;
}

.meta {
  opacity: 0.8;
  font-size: 0.9rem;
  .meta-divider {
    padding: 0 4px;
  }
}
</style>