<template>
  <section v-if="services && services.length">
    <div class="container text-center">
      <h2 class="mb-4 font-weight-bold">Our Services</h2>
      <div class="row">
        <div
          class="mb-3"
          :class="colSize"
          v-for="(service, i) in services"
          :key="i"
        >
          <div class="item">
            <img
              v-lazy="service.image"
              class="img-fluid mb-4 px-3"
              :alt="service.image"
            />
            <h4 class="font-weight-bold pt-1" v-text="service.title"></h4>
            <p
              class="text-muted wrap-text"
              style="--lines: 6"
              v-html="service.description"
            ></p>
            <inertia-link
              v-if="service.button && service.button.link"
              :href="service.button.link"
              class="btn btn-outline-dark"
            >
              {{ service.button.text || "View" }}
            </inertia-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    services() {
      return this.$page.props.services || [];
    },
    colSize() {
      return this.services.length > 2 ? "col-md-4" : "col-md-6";
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  padding: 1rem;
  padding-bottom: 1.2rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
  &:hover {
    background: var(--card-color);
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  }
}
</style>