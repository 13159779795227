<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-7 mb-3 mb-lg-0">
          <div
            class="swiper-container bg-white"
            ref="swiper-container"
            style="overflow: hidden; position: relative"
          >
            <!-- Additional required wrapper -->
            <div
              class="swiper-wrapper"
              v-if="data.data.images && data.data.images.length > 0"
            >
              <!-- Slides -->
              <div
                class="text-center swiper-slide"
                v-for="(image, i) in data.data.images"
                :key="i"
              >
                <img
                  v-lazy="image"
                  :alt="data.data.name"
                  role="link"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="swiper-wrapper" v-else>
              <div class="text-center swiper-slide">
                <img
                  v-lazy="data.data.thumbnail"
                  :alt="data.data.name"
                  role="link"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="swiper-pagination" ref="swiper-pagination"></div>
            <div class="swiper-button-prev" ref="swiper-button-prev"></div>
            <div class="swiper-button-next" ref="swiper-button-next"></div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-5">
          <h2 class="mb-0 font-weight-bold" style="color: var(--dark)">
            {{ data.data.name }}
          </h2>
          <div class="mb-3">
            <span class="badge badge-danger mr-2" v-if="data.data.for_sale"
              >For Sale</span
            >
            <span class="badge badge-danger" v-if="data.data.for_rent"
              >For Rent</span
            >
          </div>
          <table class="table table-borderless">
            <tr>
              <th>Developer name</th>
              <td>:</td>
              <td>{{ data.data.developer_name }}</td>
            </tr>
            <tr>
              <th>Project type</th>
              <td>:</td>
              <td>{{ data.data.category.name }}</td>
            </tr>
            <tr>
              <th>Location</th>
              <td>:</td>
              <td>{{ data.data.location }}</td>
            </tr>
            <tr>
              <th>Minimum price</th>
              <td>:</td>
              <th>{{ data.data.min_price }}</th>
            </tr>
            <tr>
              <th>Maximum price</th>
              <td>:</td>
              <th>{{ data.data.max_price }}</th>
            </tr>
          </table>
          <div class="mt-3 mt-lg-4">
            <a
              href="#"
              class="btn btn-primary btn-block"
              @click.prevent="
                $root.$emit('openEnquiryForm', {
                  type: 'rental-properties',
                  type_id: data.data.slug,
                })
              "
            >
              Enquire Now
              <small class="fa fa-arrow-right ml-1"></small>
            </a>
            <a
              href="#"
              class="btn btn-danger-outline btn-block mt-2"
              v-if="data.data.brochure"
              @click.prevent="downloadBrochure"
            >
              Download Brochure
              <i class="fa fa-cloud-download-alt ml-1"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        class="alert alert-success mt-4"
        v-if="data.data.offers"
        style="background: rgb(242 255 244); color: #22892e"
      >
        <h4 class="mb-2 font-weight-bold">Offer</h4>
        <span>{{ data.data.offers }}</span>
      </div>
      <div class="mt-4">
        <h3 class="mb-3 title">Details</h3>
        <table class="table table-bordered">
          <tr>
            <th>Area</th>
            <td>{{ data.data.sqft }}</td>
          </tr>
          <tr>
            <th>Size</th>
            <td>{{ data.data.size }}</td>
          </tr>
          <tr>
            <th>Height</th>
            <td>{{ data.data.height }}</td>
          </tr>
        </table>
      </div>
      <div class="mt-4">
        <h3 class="mb-3 title">Description</h3>
        <p v-html="data.data.description_raw" class="text-muted"></p>
      </div>
      <div
        class="mt-4"
        v-if="data.data.facilities && data.data.facilities.length"
      >
        <h3 class="mb-3 title">Facilities</h3>
        <div class="amenities">
          <div
            class="item"
            v-for="(feature, i) in data.data.facilities"
            :key="i"
          >
            <i class="fa fa-check-circle mr-2 text-success"></i> {{ feature }}
          </div>
        </div>
      </div>
      <div
        class="mt-4"
        v-if="
          data.data.location_advantages && data.data.location_advantages.length
        "
      >
        <h3 class="mb-3 title">Accessibility</h3>
        <div class="accessibility">
          <div
            class="item"
            v-for="(advantage, i) in data.data.location_advantages"
            :key="i"
          >
            <span class="font-weight-bold">{{ advantage.name }}</span>
            <span class="text-muted">{{ advantage.distance }}</span>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="data.data.location_advantage_details">
        <h3 class="mb-3 title">Location advantages</h3>
        <p v-html="data.data.location_advantage_details" class="text-muted"></p>
      </div>
      <div class="mt-5" style="overflow: hidden" v-if="tabs && tabs.length">
        <TabsView :tabs="tabs" @selected="tabChanged">
          <transition :name="transition" mode="out-in">
            <component v-if="selectedTab" :is="selectedTab"></component>
          </transition>
        </TabsView>
      </div>
    </div>
    <Related :exclude="data.data.slug" :title="'Related Rental Properties'" />
  </section>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css/bundle";

Swiper.use([Navigation, Pagination]);

export default {
  props: {
    data: {
      type: [Object, Array],
    },
  },
  components: {
    Related: () =>
      import(/* webpackChunkName: "home-commercials" */ "./homePage.vue"),
    TabsView: () =>
      import(
        /* webpackChunkName: "components-tabsview" */ "@/components/tabsView.vue"
      ),
    Video: () =>
      import(
        /* webpackChunkName: "shared-tabs-video" */ "../sharedTabs/video.vue"
      ),
    Gallery: () =>
      import(
        /* webpackChunkName: "shared-tabs-gallery" */ "../sharedTabs/gallery"
      ),
    FloorPlans: () =>
      import(
        /* webpackChunkName: "shared-tabs-floorplans" */ "../sharedTabs/floorPlans"
      ),
    Rera: () =>
      import(/* webpackChunkName: "shared-tabs-rera" */ "../sharedTabs/rera"),
    Map: () =>
      import(/* webpackChunkName: "shared-tabs-map" */ "../sharedTabs/map"),
  },
  data() {
    return {
      selectedTab: null,
      transition: "fade",
    };
  },
  mounted() {
    this.initSwiper();
    if (this.tabs && this.tabs.length) {
      this.selectedTab = this.tabs[0].id;
    }
  },
  methods: {
    initSwiper() {
      new Swiper(this.$refs["swiper-container"], {
        speed: 400,
        loop: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
        // spaceBetween: 30,
        pagination: {
          el: this.$refs["swiper-pagination"],
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },
      });
    },
    tabChanged(tab) {
      if (this.selectedTab == tab.id) {
        return;
      }

      if (
        this.tabs.findIndex((t) => t.id == this.selectedTab) >
        this.tabs.findIndex((t2) => t2.id == tab.id)
      ) {
        this.transition = "slide-right";
      } else {
        this.transition = "slide-left";
      }

      setTimeout(() => {
        this.selectedTab = tab.id;
      }, 50);
    },
    downloadBrochure() {
      if (!this.data.data.brochure) {
        return;
      }
      this.$root.$emit("openEnquiryForm", {
        type: "rental-properties",
        type_id: this.data.data.slug,
        meta: {
          title: "Fill in your details to download the brochure",
          callback: () => {
            // create download link
            const link = document.createElement("a");
            link.href = this.data.data.brochure;
            link.download = this.data.data.name + " Brochure";
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
        },
      });
    },
  },
  computed: {
    tabs() {
      let _arr = [];
      if (this.data.data.map_url) {
        _arr.push({
          id: "Map",
          title: "Location",
          active: false,
        });
      }

      if (this.data.data.video) {
        _arr.push({
          id: "Video",
          title: "Video",
          active: false,
        });
      }

      if (this.data.data.images.length) {
        _arr.push({
          id: "Gallery",
          title: "Gallery",
          active: false,
        });
      }

      if (this.data.data.floor_plans.length) {
        _arr.push({
          id: "FloorPlans",
          title: "Floors Plans",
          active: false,
        });
      }

      if (this.data.data.has_rera) {
        _arr.push({
          id: "Rera",
          title: "RERA details",
          active: false,
        });
      }

      _arr[0] && (_arr[0].active = true);

      return _arr;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 2rem 1.2rem;
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

h3.title:after {
  content: "";
  margin-top: 12px;
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  background: var(--primary);
  display: block;
}

.swiper-container {
  height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.amenities {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  .item {
    width: calc(33% - 0.5rem);
    padding: 10px;
    border-radius: var(--border-radius);
    color: var(--dark);
    box-shadow: 0 0.375rem 1.2rem 0 rgb(209 209 209 / 13%);
    border: 1px solid var(--lighter);
    background: var(--white);
    font-weight: 500;
    @media (max-width: 767px) {
      width: calc(50% - 0.5rem);
    }
  }
}

.accessibility {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  .item {
    width: calc(50% - 0.5rem);
    padding: 0.8rem;
    border-radius: var(--border-radius);
    color: var(--dark);
    box-shadow: 0 0.375rem 1.2rem 0 rgb(209 209 209 / 13%);
    border: 1px solid var(--lighter);
    background: var(--white);
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>