<template>
  <div>
    <section id="hero">
      <div class="hero-container">
        <div
          id="heroCarousel"
          class="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <div class="carousel-inner" role="listbox">
            <div
              v-for="(slide, index) in data"
              :key="index"
              class="carousel-item"
              :class="{ active: index === 0 }"
              :style="`background-image: url('${asset(
                'images/slider/' + slide.img
              )}')`"
            >
              <div class="carousel-container">
                <div class="carousel-content container">
                  <h2 class="animated fadeInDown">{{ slide.title }}</h2>
                  <p class="animated fadeInUp">
                    {{ slide.description }}
                  </p>
                  <a
                    :href="slide.button_redirect"
                    v-if="slide.button_redirect && slide.button_title"
                    class="scrollto btn-get-started animated fadeInUp"
                    style="position: relative; z-index: 9"
                  >
                    {{ slide.button_title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <template v-if="data.length > 1">
            <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>
            <a
              class="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon fa fa-angle-left"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon fa fa-angle-right"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </template>
        </div>
      </div>
    </section>
    <div class="container hero-search-container">
      <div class="hero-search">
        <h3 class="mb-1 font-weight-bold">
          Find a place to live, work and invest
        </h3>
        <p class="mb-4 text-muted">One stop solution for all property needs</p>
        <form @submit.prevent="searchSubmit">
          <div class="form-row">
            <div class="col-lg-3 col-md-6">
              <select class="form-control" v-model="form.type">
                <option value="buy-sale">Buy/Sell properties</option>
                <option value="rental-properties">Rental properties</option>
              </select>
            </div>
            <div class="col mt-3 mt-md-0">
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="form.search"
              />
            </div>
            <div class="col-lg-auto col-md-12 text-center mt-3 mt-lg-0">
              <button class="btn btn-primary w-100">Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      form: this.$inertia.form({
        search: "",
        type: "buy-sale",
      }),
    };
  },
  mounted() {
    setTimeout(() => {
      this.getSlider();
    }, 100);
  },
  methods: {
    getSlider() {
      axios.get(this.$route("api.sliders")).then((response) => {
        this.data = response.data;
      });
    },
    searchSubmit() {
      let _route = null;
      if (this.form.type == "buy-sale") {
        _route = "home.residential.index";
      }
      if (this.form.type == "rental-properties") {
        _route = "home.commercial.index";
      }

      if (_route) {
        this.$inertia.visit(
          this.$route(_route, {
            search: this.form.search,
          })
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#hero {
  width: 100%;
  --height: calc(80vh - 114px);
  --height: max(calc(80vh - 114px), 400px);
  height: var(--height);
  padding: 0;
  overflow: hidden;

  @media (max-width: 992px) {
    margin-top: -16px;
  }
}

#hero .carousel-item {
  width: 100%;
  height: var(--height);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  overflow: hidden;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(13, 30, 45, 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

#hero .carousel-content {
  text-align: center;
  margin-bottom: 4rem;
}

@media (max-width: 992px) {
  #hero .carousel-content.container {
    padding: 0 50px;
  }
}

#hero h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 600;
}

#hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  color: #fff;
  font-family: var(--font-family-secondary);
  font-size: 1.2rem;
  font-weight: normal;
  opacity: 0.8;
}

#hero .carousel-control-next,
#hero .carousel-control-prev {
  z-index: 5;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-left,
#hero .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .carousel-indicators li {
  cursor: pointer;
}

#hero .btn-get-started {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: var(--border-radius);
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: var(--primary);
  border: 2px solid var(--primary);
}

#hero .btn-get-started:hover {
  background: var(--primary-dark);
  border-color: var(--primary-dark);
}

#hero .btn-get-started:hover:after {
  right: -8px;
  content: "\f061";
  color: #fff;
}

#hero .btn-get-started:after {
  position: relative;
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  font-size: 12px;
  color: var(--white);
  transition: all 0.3s ease 0s;
  font-weight: bold;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 1.8rem;
  }

  #hero p {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

.hero-container {
  position: relative;
  z-index: 1;
}

.hero-container .clip-svg {
  height: 100%;
  z-index: 2;
  position: absolute;
  bottom: -2px;
  width: 160%;
  transform: translateX(-50%);
  left: 50%;
}

.hero-container .clip-svg path {
  fill: var(--bg-color);
}

@media (max-width: 992px) {
  .hero-container .clip-svg {
    width: 229%;
  }
}

.hero-search-container {
  margin-bottom: -4rem;
}

.hero-search {
  background: var(--card-color);
  padding: 2.2rem 1.8rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  position: relative;
  transform: translateY(-6rem);
  z-index: 10;
  max-width: 800px;
  margin: auto;
}
</style>